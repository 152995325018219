:root {
  --orange:#ffb03b;
  --lightorange:#f6d60a;
  --red:#b31f24;
  --darkred:#901a1c;
}

.frame {
  /* width: 100vw;
  height: 100vh; */
  overflow: hidden;
}

.bg-orange {
  background-color: var(--orange);
}
.bg-red {
  background-color: var(--red);
}
.bg-darkred {
  background-color: var(--darkred);
}

h2, h3 {
  font-family: "Satisfy";
}

.stars {
  color: var(--orange)
}

.hero h2 {
  color: white;
  font-weight: 700;
  font-size: 38px;
}

h2 span {
  color: var(--orange);
}



 .menu {
     z-index: 4;
 }

.hero::after{
    content: "";
    background-color: #111;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.6;
    z-index: -2;
    top: 0;
    left: 0;
}

.hero {
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-background-size: cover;
    position: relative;
    min-height: 70vh;
    width: 100%;
    z-index: 1;
    color: white;
    text-align: center;
    background-color: var(--darkred);
}


.btn {
    border-color: transparent;
    border: 0px;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 0 10px;
    animation-delay: 0.8s;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    z-index: 3;
  }
  
  .btn:hover {
    background-color: var(--darkred);
    color: #fff;
  }

  .btn-orange {
    background-color: var(--orange);
    border-color: var(--orange);
    border: 1px solid var(--orange);
    z-index: 4;}
  
  .btn-orange:hover {
    background-color: var(--darkred);
  }
  
  
  
  .btn-red {
    background-color: var(--darkred);
    border-color: var(--darkred);
  }



  .content {
  background: #fffaf3;

  }

  .content h3 {
    font-weight: 400;
    font-size: 34px;
    color: #5f5950;
  }

  .section-title {
    text-align: center;
    padding-bottom: 30px;
    /* padding-top: 160px; */
    background-color: var(--orange);
  }
  
  .section-title h2 {
    margin: 15px 0 0 0;
    font-size: 32px;
    font-weight: 700;
    color: #5f5950;
  }
  
  .section-title h2 span {
    color: white;
  }

  .social{
    color: var(--orange);
    transition: 1s;
  
  }

  .social:hover{
    color: var(--red);
  }